import { Button, withStyles, WithStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { spacing } from '../../constants/theme/spacing';

type BackButtonProps = WithStyles;

const BackButton: FunctionComponent<BackButtonProps> = ({ classes }) => (
  <Button
    variant="contained"
    color="primary"
    fullWidth={true}
    className={classes.spacing6x}
    onClick={() => history.back()}
  >
    Back
  </Button>
);

export default compose<BackButtonProps, {}>(withStyles(spacing))(BackButton);

