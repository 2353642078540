import { createMuiTheme, Theme } from '@material-ui/core/styles';
import generic from '../constants/theme/generic';

const genericTheme = () => createMuiTheme(generic);

export default (): Theme => {
  return genericTheme();
};

const combineStyles = (...styles: any) => {
  return (theme: Theme) => {
    const outStyles = styles.map((arg: any) => {
      if (typeof arg === 'function') {
        return arg(theme);
      }
      return arg;
    });
    return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
  };
};

export { combineStyles };
