import { createStyles, Theme } from '@material-ui/core';

const space = (theme: Theme, factor: number) => theme.spacing.unit * factor;

const spacing = (theme: Theme) =>
  createStyles({
    spacing: {
      marginTop: space(theme, 2),
    },
    spacing2x: {
      marginTop: space(theme, 4),
    },
    spacing4x: {
      marginTop: space(theme, 8),
    },
    spacing6x: {
      marginTop: space(theme, 12),
    },
    dspacing2x: {
      margin: `${space(theme, 4)}px 0`,
    },
  });

export { spacing };
