import { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import FAQ from './components/FAQ/FAQ';
import NotFound from './components/NotFound/NotFound';
import Redirect404 from './components/ui/Redirect404';
import routes from './constants/routes';
import { HealthCheck } from './components/HealthCheck';

const Router: FunctionComponent<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route exact={true} path={routes.HEALTH_CHECK} component={HealthCheck} />
      <Route exact={true} path={routes.FAQ} component={FAQ} />
      <Route exact={true} path={routes.ERROR} component={NotFound} />
      <Redirect404 to={routes.ERROR} />;
    </Switch>
  );
};

export default compose<RouteComponentProps, {}>(
  withRouter,
)(Router);
